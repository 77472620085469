import { queryAll } from "./dom"

export function createScriptTag(script) {
    const newScript = document.createElement("script")

    newScript.innerHTML = script.innerHTML
    newScript.type = "text/javascript"

    if (script.src) {
        newScript.src = script.src
    }
    return newScript
}

export function runScriptsInsideElement(element) {
    queryAll("script:not([data-component])", element).forEach((script) => {
        const newScript = createScriptTag(script)
        script.parentNode.insertBefore(newScript, script)
        script.parentNode.removeChild(script)
    })
}
